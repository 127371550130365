<template>
	<v-container
		v-if="$can([titlePerms + '_list'])"
		id="crud"
		fluid
		tag="section"
	>
		<v-card>
			<v-toolbar flat color="white">
				<v-toolbar-title
					>Administración de {{ title }} de {{ $appName }}</v-toolbar-title
				>
			</v-toolbar>

			<v-data-table
				:headers="showHeaders"
				:items="filteredData"
				:search="search"
				sort-by="username"
				class="elevation-1"
			>
				<template v-slot:[`header.username`]="{ header }">
					<filter-component
						v-bind:header="{ header }"
						v-bind:filters="filters"
					></filter-component>
				</template>
				<template v-slot:[`header.lastName`]="{ header }">
					<filter-component
						v-bind:header="{ header }"
						v-bind:filters="filters"
					></filter-component>
				</template>
				<template v-slot:[`header.firstName`]="{ header }">
					<filter-component
						v-bind:header="{ header }"
						v-bind:filters="filters"
					></filter-component>
				</template>
				<template v-slot:[`header.nombre`]="{ header }">
					<filter-component
						v-bind:header="{ header }"
						v-bind:filters="filters"
					></filter-component>
				</template>
				<template v-slot:[`header.enabled`]="{ header }">
					<filter-component
						v-bind:header="{ header }"
						v-bind:filters="filters"
					></filter-component>
				</template>
				<template v-slot:[`item.firstName`]="{ item }">
					{{ item.firstName + " " + item.lastName }}
				</template>
				<template v-slot:[`item.emailVerified`]="{ item }">
					<div
						:class="$getCss(item.emailVerified, 'activo')"
						style="width: 50px"
						dark
					>
						{{ $getValue(item.emailVerified, "activo") }}
					</div>
				</template>
				<template v-slot:[`item.totp`]="{ item }">
					<div :class="$getCss(item.totp, 'activo')" style="width: 50px" dark>
						{{ $getValue(item.totp, "activo") }}
					</div>
				</template>
				<template v-slot:[`item.enabled`]="{ item }">
					<div
						:class="$getCss(item.enabled, 'activo')"
						style="width: 50px"
						dark
					>
						{{ $getValue(item.enabled, "activo") }}
					</div>
				</template>
				<template v-slot:[`item.createdTimestamp`]="{ item }">
					{{ $formatDateFromTimestamp(item.createdTimestamp) }}
				</template>
				<template v-slot:top>
					<v-toolbar flat color="white" class="pr-4">
						<v-row>
							<v-col sm="6" class="pt-20">
								<v-text-field
									v-model="search"
									append-icon="mdi-magnify"
									:label="'Busqueda de ' + title"
									single-line
									hide-details
								></v-text-field>
							</v-col>
							<v-col sm="2"></v-col>
							<v-col sm="3" class="">
								<v-autocomplete
									v-model="selectedHeaders"
									:items="headers"
									label="Columnas Visibles"
									multiple
									return-object
								>
									<template v-slot:selection="{ item, index }">
										<v-chip v-if="index < 2">
											<span>{{ item.text }}</span>
										</v-chip>
										<span v-if="index === 2" class="grey--text caption"
											>(otras {{ selectedHeaders.length - 2 }}+)</span
										>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col sm="1">
								<v-dialog v-model="dialog" max-width="70%" persistent>
									<template
										v-if="$can([titlePerms + '_create'])"
										v-slot:activator="{ on, attrs }"
									>
										<v-btn
											:color="$cv('principal')"
											dark
											class="mx-2"
											fab
											v-bind="attrs"
											v-on="on"
											><v-icon blue>mdi-plus</v-icon></v-btn
										>
									</template>
									<v-card>
										<v-form ref="form" v-model="valid" lazy-validation>
											<v-toolbar dark :color="$cv('principal')">
												<v-btn icon dark @click="dialog = false">
													<v-icon>mdi-close</v-icon>
												</v-btn>
												<v-toolbar-title>{{
													editedIndex != -1
														? formTitle + ": " + editedItem.username
														: formTitle
												}}</v-toolbar-title>
												<v-spacer></v-spacer>
												<v-toolbar-items>
													<v-btn
														dark
														text
														@click="$save()"
														v-if="!vista"
														:disabled="!valid"
														>Guardar</v-btn
													>
												</v-toolbar-items>
											</v-toolbar>

											<v-card-text style="overflow-y: scroll; max-height: 92vh">
												<v-container>
													<v-row>
														<v-col cols="12" md="6" sm="12">
															<v-row>
																<v-col
																	v-if="editedIndex == -1"
																	cols="12"
																	md="12"
																	sm="12"
																	class="pa-0 px-2"
																>
																	<v-text-field
																		v-model="editedItem.username"
																		label="Nombre de Usuario"
																		:disabled="editedIndex != -1 ? true : false"
																		:rules="[
																			$rulesRequerido,
																			$rulesAlfaNum,
																			$rulesMax500,
																		]"
																		:title="
																			editedIndex != -1
																				? 'El nombre de usuario no se puede editar'
																				: ''
																		"
																	></v-text-field>
																</v-col>
																<v-col
																	cols="12"
																	:md="editedIndex == -1 ? 6 : 6"
																	:sm="editedIndex == -1 ? 6 : 6"
																	class="pa-0 px-2"
																>
																	<v-text-field
																		v-model="editedItem.lastName"
																		label="Apellido"
																		:disabled="vista"
																		:rules="[
																			$rulesRequerido,
																			$rulesAlfaNum,
																			$rulesMax500,
																		]"
																	></v-text-field>
																</v-col>
																<v-col
																	cols="12"
																	:md="editedIndex == -1 ? 6 : 6"
																	:sm="editedIndex == -1 ? 6 : 6"
																	class="pa-0 px-2"
																>
																	<v-text-field
																		v-model="editedItem.firstName"
																		label="Nombres"
																		:disabled="vista"
																		:rules="[
																			$rulesRequerido,
																			$rulesAlfaNum,
																			$rulesMax500,
																		]"
																	></v-text-field>
																</v-col>
															</v-row>

															<v-row>
																<!--
                            <v-col cols="12" md="12" sm="12">
                              <v-autocomplete
                                :items="roles"
                                v-model="editedItem.roles"
                                item-value="id"
                                item-text="name"
                                chips
                                label="Roles"
                                multiple
                                outlined
                                return-object
                              >
                              </v-autocomplete>
                            </v-col>
                          -->
															</v-row>
															<v-row>
																<v-col
																	cols="12"
																	md="6"
																	sm="6"
																	class="pa-0 px-2"
																>
																	<v-text-field
																		v-model="editedItem.email"
																		:disabled="vista"
																		label="Email"
																		:rules="[$rulesRequerido, $rulesMail]"
																	></v-text-field>
																</v-col>
																<v-col
																	cols="12"
																	md="6"
																	sm="6"
																	class="pa-0 px-2"
																>
																	<v-text-field
																		type="password"
																		:disabled="vista"
																		v-model="editedItem.password"
																		label="Contraseña"
																	></v-text-field>
																</v-col>
															</v-row>
														</v-col>
														<v-col cols="12" md="6" sm="12">
															<v-row>
																<v-col
																	cols="12"
																	md="12"
																	sm="6"
																	class="rounded"
																	:style="{
																		backgroundColor: $cv('primary') + '20',
																	}"
																>
																	<v-row>
																		<v-col
																			cols="12"
																			:md="editedIndex == -1 ? 12 : 6"
																			:sm="editedIndex == -1 ? 12 : 6"
																			class="pa-0 px-2"
																		>
																			<v-autocomplete
																				:items="itemsSN"
																				item-value="id"
																				:disabled="vista"
																				item-text="detalle"
																				label="Habilitado"
																				v-model="editedItem.enabled"
																				:rules="[$rulesRequerido]"
																			></v-autocomplete>
																		</v-col>

																		<v-col
																			cols="12"
																			:md="editedIndex == -1 ? 12 : 6"
																			:sm="editedIndex == -1 ? 12 : 6"
																			class="pa-0 px-2"
																		>
																			<v-autocomplete
																				:items="itemsSN"
																				item-value="id"
																				item-text="detalle"
																				:disabled="vista"
																				label="Email Verificado"
																				v-model="editedItem.emailVerified"
																				:rules="[$rulesRequerido]"
																			></v-autocomplete>
																		</v-col>
																		<v-col
																			cols="12"
																			md="12"
																			sm="12"
																			class="pa-0 px-2"
																		>
																			<v-autocomplete
																				:items="itemsSN"
																				item-value="id"
																				item-text="detalle"
																				:disabled="vista"
																				label="2nd Factor Habilitado"
																				v-model="editedItem.totp"
																				:rules="[$rulesRequerido]"
																			></v-autocomplete>
																		</v-col>
																	</v-row>
																</v-col>
															</v-row>
														</v-col>
													</v-row>

													<v-row>
														<v-col cols="12" md="2" sm="6" class="pa-0 px-2">
															<v-autocomplete
																v-model="editedItem.attributes.tipo_documento"
																:items="identifiertypes"
																item-text="name"
																item-value="id"
																label="Tipo Documento"
																:rules="[$rulesRequerido]"
															></v-autocomplete>
														</v-col>
														<v-col cols="12" md="4" sm="6" class="pa-0 px-2">
															<v-text-field
																v-model="editedItem.attributes.documento"
																label="Documento"
																:disabled="vista"
																:rules="[$rulesRequerido, $rulesAlfaNum]"
																maxlength="9"
															></v-text-field>
														</v-col>
														<v-col cols="12" md="3" sm="6" class="pa-0 px-2">
															<v-text-field
																v-model="editedItem.attributes.telefono"
																label="Telefono"
																:rules="[
																	$rulesRequerido,
																	$rulesAlfaNum,
																	$rulesMax500,
																]"
															></v-text-field>
														</v-col>
														<v-col cols="12" md="3" sm="6" class="pa-0 px-2">
															<v-text-field
																v-model="editedItem.attributes.celular"
																label="Celular"
																:rules="[
																	$rulesRequerido,
																	$rulesAlfaNum,
																	$rulesMax500,
																]"
															></v-text-field>
														</v-col>
													</v-row>
													<v-row
														v-if="!editedItem.userRoles.length"
														dense
														aling="center"
														class="text-fields-row"
													>
														<v-col cols="12" md="12" sm="12">
															<v-btn small color="primary" @click="add" dark>
																Agregar Rol
															</v-btn>
														</v-col>
													</v-row>

													<v-row v-else>
														<v-col cols="12" md="12" sm="12">
															<v-data-table
																:headers="headersRoles"
																:items="editedItem.userRoles"
																class="rounded"
																:style="{
																	backgroundColor: $cv('primary') + '20',
																}"
																:hide-default-footer="true"
																:key="keyTableUserRoles"
																item-key="index"
															>
																<template v-slot:[`item.entidades`]="{ item }">
																	<v-autocomplete
																		:items="entidades"
																		item-value="id"
																		item-text="nombre"
																		label="Entidad"
																		v-model="item.entidades"
																		:rules="[$rulesRequerido]"
																	></v-autocomplete>
																</template>
																<template v-slot:[`item.clients`]="{ item }">
																	<v-autocomplete
																		:items="clients"
																		item-value="id"
																		item-text="name"
																		label="Producto"
																		v-model="item.clients"
																		:rules="[$rulesRequerido]"
																		@change="getRoles(item.index)"
																		return-object
																	></v-autocomplete>
																</template>
																<template v-slot:[`item.role`]="{ item }">
																	<v-autocomplete
																		:items="item.rolesList"
																		item-value="id"
																		item-text="roleName"
																		label="Roles"
																		v-model="item.role"
																		:rules="[$rulesRequerido]"
																		return-object
																		multiple
																	></v-autocomplete>
																</template>

																<template v-slot:[`item.actions`]="{ item }">
																	<v-col cols="auto pa-0">
																		<v-btn
																			:color="$cv('btnReset')"
																			fab
																			x-small
																			dark
																			title="Reset"
																			@click="resetRow(item.index)"
																			class="x-btn-grid"
																		>
																			<v-icon>mdi-file-restore-outline</v-icon>
																		</v-btn>
																		<v-btn
																			:color="$cv('btnEliminar')"
																			fab
																			x-small
																			dark
																			title="Eliminar Rol"
																			@click="remove(item.index)"
																			class="x-btn-grid"
																		>
																			<v-icon>mdi-account-minus</v-icon>
																		</v-btn>
																		<v-btn
																			:color="$cv('btnEditar')"
																			fab
																			x-small
																			dark
																			title="Agregar Rol"
																			@click="add"
																			class="x-btn-grid"
																		>
																			<v-icon> mdi-account-plus </v-icon>
																		</v-btn>
																	</v-col>
																</template>
															</v-data-table>
														</v-col>
														<!--
                                <v-col
                                  cols="12"
                                  md="6"
                                  sm="6"
                                  class="pa-0 px-2 pb-6"
                                >
                                  <v-autocomplete
                                    :items="clients"
                                    item-value="id"
                                    item-text="name"
                                    label="Producto"
                                    v-model="editedItem.clients"
                                    :disabled="vista"
                                    :rules="[$rulesRequerido]"
                                    return-object
                                    @change="getRoles()"
                                  ></v-autocomplete
                                ></v-col>

                                <v-col
                                  cols="12"
                                  md="6"
                                  sm="6"
                                  class="pa-0 px-2 pb-6"
                                >
                                  <v-autocomplete
                                    :items="rolesList"
                                    item-value="id"
                                    item-text="name"
                                    label="Roles"
                                    v-model="editedItem.role"
                                    :disabled="vista"
                                    :rules="[$rulesRequerido]"
                                    return-object
                                    multiple
                                  ></v-autocomplete
                                ></v-col>
                                -->
													</v-row>
												</v-container>
											</v-card-text>
										</v-form>
									</v-card>
								</v-dialog>
							</v-col>
						</v-row>
					</v-toolbar>
					<v-dialog v-model="dialogHistorial" max-width="70%" persistent>
						<v-card>
							<v-form ref="form" v-model="valid" lazy-validation>
								<v-toolbar dark color="primary">
									<v-btn icon dark @click="dialogHistorial = false">
										<v-icon>mdi-close</v-icon>
									</v-btn>
									<v-toolbar-title>{{ titleHistorial }}</v-toolbar-title>
									<v-spacer></v-spacer>
								</v-toolbar>
								<v-card-text style="overflow-y: scroll; max-height: 72vh">
									<v-data-table
										:headers="headersHistorial"
										:items="historial"
										sort-by="start"
										class="elevation-1"
									>
										<template v-slot:[`item.start`]="{ item }">
											{{ $formatDateFromTimestamp(item.start) }}
										</template>
										<template v-slot:[`item.lastAccess`]="{ item }">
											{{ $formatDateFromTimestamp(item.lastAccess) }}
										</template>
										<template v-slot:[`item.clients`]="{ item }">
											{{ JSON.stringify(item.clients) }}
										</template>
									</v-data-table>
								</v-card-text>
							</v-form>
						</v-card>
					</v-dialog>
				</template>

				<template v-slot:[`item.actions`]="{ item }">
					<v-row class="">
						<v-col cols="6" md="6" sm="6" style="padding: 6px 0px 0px 0px">
							<v-menu
								bottom
								origin="center center"
								transition="scale-transition"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										color="transparent elevation-0"
										dark
										small
										v-bind="attrs"
										v-on="on"
										min-width="30px"
									>
										<v-icon
											large
											color="blue-grey lighten-3"
											style="font-size: 30px"
										>
											mdi-dialpad
										</v-icon>
									</v-btn>
								</template>

								<v-list>
									<v-list-item
										v-if="$can([titlePerms + '_read'])"
										@click="
											vista = true;
											$editItem(item.id);
										"
									>
										<v-list-item-icon class="mr-0"
											><v-icon
												small
												class="mr-2"
												:color="$cv('btnVista')"
												title="Ver"
											>
												mdi-eye-outline
											</v-icon> </v-list-item-icon
										><v-list-item-content>
											<v-list-item-title>Ver</v-list-item-title>
										</v-list-item-content>
									</v-list-item>

									<v-list-item
										v-if="$can([titlePerms + '_update'])"
										@click="
											vista = false;
											$editItem(item.id);
										"
									>
										<v-list-item-icon class="mr-0"
											><v-icon
												small
												class="mr-2"
												:color="$cv('btnEditar')"
												title="Editar"
											>
												mdi-pencil
											</v-icon> </v-list-item-icon
										><v-list-item-content>
											<v-list-item-title>Editar</v-list-item-title>
										</v-list-item-content>
									</v-list-item>

									<v-list-item
										v-if="$can([titlePerms + '_delete'])"
										@click="$deleteItem(item.id, item.username)"
									>
										<v-list-item-icon class="mr-0">
											<v-icon
												small
												class="mr-2"
												:color="$cv('btnEliminar')"
												title="Eliminar"
											>
												mdi-delete
											</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>Eliminar</v-list-item-title>
										</v-list-item-content>
									</v-list-item>

									<v-list-item
										v-if="$can([titlePerms + '_changestatus'])"
										@click="$enabledItem(item)"
									>
										<v-list-item-icon class="mr-0">
											<v-icon
												medium
												class="mr-2"
												:color="
													item.enabled == false
														? $cv('btnActivo')
														: $cv('btnInactivo')
												"
												:title="
													item.enabled == false
														? $cv('titleActivar')
														: $cv('titleDesactivar')
												"
											>
												mdi-archive-arrow-down
											</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>{{
												item.enabled == false
													? $cv("titleActivar")
													: $cv("titleDesactivar")
											}}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item
										v-if="$can([titlePerms + '_list_sesiones'])"
										@click="getSessions(item)"
									>
										<v-list-item-icon class="mr-0">
											<v-icon
												small
												class="mr-2"
												:color="$cv('principal')"
												title="Consultar Sesiones"
											>
												mdi-security
											</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>Consultar Sesiones</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-col>
					</v-row>
				</template>
			</v-data-table>
		</v-card>

		<v-snackbar
			v-model="snackbar"
			:bottom="true"
			:color="color"
			:timeout="timeout"
		>
			<div v-html="text"></div>

			<template v-slot:action="{ attrs }">
				<v-btn dark text v-bind="attrs" @click="snackbar = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
		<vue-confirm-dialog></vue-confirm-dialog>
	</v-container>
</template>

<script>
	import Swal from "sweetalert2";

	function title() {
		return "Usuarios";
	}

	export default {
		data: (vm) => ({
			valid: true,
			nowDate: new Date().toISOString().slice(0, 10),
			_method: "PUT",
			autoGrow: true,
			rows: 1,
			title: title(),
			dessertActivo: "",
			route: "usuarios",
			titlePerms: title().toLowerCase(),
			routeSessions: "usuarios_sessions",
			routeRolesByClient: "roles_client",
			menu: false,
			modal: false,
			menu2: false,
			dialog: false,
			snackbar: false,
			visible: true,
			text: "Registro Insertado",
			color: "success",
			timeout: 4000,
			rules: [(v) => v.length <= 500 || "Max 500 caracteres"],
			search: "",
			vista: false,
			users: false,

			keyTableUserRoles: 0,
			headers: [
				{ text: "Acciones", value: "actions", sortable: false },
				/*{ text: "Id", align: "start", sortable: false, value: "id",},*/
				{ text: "Usuario", value: "username" },
				//{ text: "Apellido", value: "lastName" },
				{ text: "Nombre y Apellido", value: "nombre" },
				{ text: "Mail", value: "email" },
				{
					text: "Email Confirmado",
					value: "emailVerified",
					width: 40,
				},
				{ text: "2nd Factor Habilitado", value: "totp", width: 60 },
				{ text: "Habilitado", value: "enabled", width: 60 },
				{ text: "Creado", value: "createdTimestamp" },
			],
			titleHistorial: "",
			dialogHistorial: false,
			headersHistorial: [
				{ text: "Id Sesion", value: "id", align: "center" },
				{ text: "Fecha", value: "start", align: "center" },
				{ text: "Último Acceso", value: "lastAccess", align: "center" },
				{ text: "IP", value: "ipAddress", align: "center" },
				{ text: "Producto", value: "clients", align: "center" },
			],
			headersRoles: [
				{ text: "Entidad", value: "entidades", align: "left" },
				{ text: "Producto", value: "clients", align: "left" },
				{ text: "Rol", value: "role", align: "left" },
				{
					text: "Acciones",
					value: "actions",
					sortable: false,
					align: "center",
					class: "acciones-width",
				},
			],
			historial: [],
			desserts: [],
			editedIndex: -1,
			editedItem: {
				id: "",
				username: "",
				firstName: "",
				lastName: "",
				email: "",
				enabled: "",
				totp: "",
				clients: "",
				entidades: "",
				userRoles: [{ index: 0, rolesList: [] }],
				attributes: {},
			},
			defaultItem: {
				id: "",
				username: "",
				firstName: "",
				lastName: "",
				email: "",
				enabled: "",
				totp: "",
				clients: "",
				entidades: "",
				userRoles: [{ index: 0, rolesList: [] }],
				attributes: {},
			},
			filters: {
				username: "",
				firstName: "",
				lastName: "",
				enabled: "",
				nombre: "",
			},
			filterKey: [],
			roles: [],
			clients: [],
			entidades: [],
			selectedHeaders: [],
			itemsSN: [
				{ id: true, detalle: "SI" },
				{ id: false, detalle: "NO" },
			],
			identifiertypes: [],
		}),

		computed: {
			formTitle() {
				return this.editedIndex === -1
					? "Registrar " + this.title
					: this.vista == false
					? "Editar " + this.title
					: this.title;
			},
			filteredData() {
				return this.$filteredData().data !== undefined
					? this.$filteredData().data
					: this.$filteredData();
			},
			showHeaders() {
				return this.headers.filter((s) => this.selectedHeaders.includes(s));
			},
		},

		watch: {
			async dialog(val) {
				if (!val) {
					this.$close();
				} else {
					try {
            this.identifiertypes=[
    {
      "id": 1,
      "name": "CUIL",
      "description": "Clave Única de Identificación Laboral",
      "juridictionType": 0,
      "isForLegalPerson": false,
      "isForJuridicalPerson": false,
      "identifierMask": null,
      "validationRegex": null,
      "isActive": true,
      "isDeleted": false
    },
    {
      "id": 2,
      "name": "CUIT",
      "description": "Clave Única de Identificación Tributaria",
      "juridictionType": 1,
      "isForLegalPerson": false,
      "isForJuridicalPerson": true,
      "identifierMask": "##-########-#",
      "validationRegex": "^\\d{2}-\\d{8}-\\d$",
      "isActive": true,
      "isDeleted": false
    },
    {
      "id": 3,
      "name": "CUIL",
      "description": "Clave Única de Identificación Laboral",
      "juridictionType": 0,
      "isForLegalPerson": false,
      "isForJuridicalPerson": false,
      "identifierMask": null,
      "validationRegex": null,
      "isActive": false,
      "isDeleted": false
    },
    {
      "id": 4,
      "name": "DNI",
      "description": "Documento Nacional de Identidad",
      "juridictionType": 0,
      "isForLegalPerson": false,
      "isForJuridicalPerson": false,
      "identifierMask": "########",
      "validationRegex": "^[0-9]{7,8}$",
      "isActive": true,
      "isDeleted": false
    },
    {
      "id": 1002,
      "name": "PERSONERIA JURIDICA",
      "description": "PERSONERIA JURIDICA",
      "juridictionType": 1,
      "isForLegalPerson": false,
      "isForJuridicalPerson": true,
      "identifierMask": null,
      "validationRegex": null,
      "isActive": false,
      "isDeleted": false
    },
    {
      "id": 1003,
      "name": "PASAPORTE",
      "description": "Pasaporte",
      "juridictionType": 0,
      "isForLegalPerson": false,
      "isForJuridicalPerson": false,
      "identifierMask": "AAA######",
      "validationRegex": "^[a-zA-Z]{3}[0-9]{6}$",
      "isActive": true,
      "isDeleted": false
    },
    {
      "id": 1004,
      "name": "LE",
      "description": "Libreta de Enrolamiento",
      "juridictionType": 0,
      "isForLegalPerson": false,
      "isForJuridicalPerson": false,
      "identifierMask": "#########",
      "validationRegex": "^[0-9]{1,9}$",
      "isActive": true,
      "isDeleted": false
    },
    {
      "id": 1005,
      "name": "LC",
      "description": "Libreta Cívica",
      "juridictionType": 0,
      "isForLegalPerson": false,
      "isForJuridicalPerson": false,
      "identifierMask": "#########",
      "validationRegex": "^[0-9]{1,9}$",
      "isActive": true,
      "isDeleted": false
    },
    {
      "id": 1006,
      "name": "CI",
      "description": "Cédula de Identidad Argentina",
      "juridictionType": 0,
      "isForLegalPerson": false,
      "isForJuridicalPerson": false,
      "identifierMask": "#########",
      "validationRegex": "^[0-9]{1,9}$",
      "isActive": true,
      "isDeleted": false
    },
    {
      "id": 1007,
      "name": "DNI EX",
      "description": "DNI Extranjero",
      "juridictionType": 0,
      "isForLegalPerson": false,
      "isForJuridicalPerson": false,
      "identifierMask": "#########",
      "validationRegex": "^[0-9]{1,9}$",
      "isActive": true,
      "isDeleted": false
    },
    {
      "id": 1008,
      "name": "CI EX",
      "description": "Cédula de Identidad Extranjero",
      "juridictionType": 0,
      "isForLegalPerson": false,
      "isForJuridicalPerson": false,
      "identifierMask": "#########",
      "validationRegex": "^[0-9]{1,9}$",
      "isActive": true,
      "isDeleted": false
    },
    {
      "id": 1009,
      "name": "CDI",
      "description": "Clave de Identificación",
      "juridictionType": 0,
      "isForLegalPerson": false,
      "isForJuridicalPerson": false,
      "identifierMask": "#########",
      "validationRegex": "^[0-9]{1,9}$",
      "isActive": true,
      "isDeleted": false
    },
    {
      "id": 1010,
      "name": "NO CONSTA",
      "description": "No Consta",
      "juridictionType": 0,
      "isForLegalPerson": false,
      "isForJuridicalPerson": false,
      "identifierMask": null,
      "validationRegex": null,
      "isActive": false,
      "isDeleted": false
    }
  ];
						//this.$getData(["identifiertypes"]);
						if (this.editedIndex != -1) {
							const result = await this.getRolesEdit();
							if (result === true) {
							} else {
								this.snackbar = true;
								this.text = "Error al obtener datos.";
								this.color = "error";
							}
							//
						}
					} catch (error) {
						Swal.close();
						this.snackbar = true;
						this.text = "Error al obtener datos. Error: " + error;
						this.color = "error";
					}
				}
			},
		},

		created() {
			this.$initialize();
			this.$getData(["clients", "entidades"]);
			//this.$getListForSelect("clients");
			this.selectedHeaders = this.headers;
			if (!this.editedItem.userRoles.length) {
				this.add();
			}
		},

		methods: {
			async getRolesEdit() {
				//console.log("buscando roles");
				const response = await new Promise((resolve) => {
					let clients = this.editedItem.userRoles;
					for (let i = 0; i < clients.length; i++) {
						//console.log("buscando roles " + i);
						//console.log(clients[i].index);
						this.getRoles(clients[i].index);
					}
					resolve(true);
				});
				//console.log("fin buscando roles");
				return response;
			},

			add() {
				var i = this.editedItem.userRoles.length;
				this.editedItem.userRoles.push({
					entidades: "",
					clients: "",
					role: "",
					id: i,
					index: i,
					rolesList: [],
				});
			},
			remove(index) {
				this.editedItem.userRoles.splice(index, 1);
			},
			forceRerender(child) {
				this.componentKey += 1;
			},
			resetRow(index) {
				this.editedItem.userRoles[index].entidades = { id: "", name: "" };
				this.editedItem.userRoles[index].clients = { id: "", name: "" };
				this.editedItem.userRoles[index].role = { id: "", name: "" };
				this.editedItem.userRoles[index].rolesList = [];
			},
			async getRoles(index) {
				if (this.editedItem.userRoles[index].clients) {
					this.editedItem.userRoles[index].rolesList = [];

					let clientId = this.editedItem.userRoles[index].clients.name;
					//console.log(clientId);
					/*
																				        this.editedItem.userRoles[index].rolesList = [
																				          {
																				            id: "21a6d144-5eeb-4195-bcb7-65254830081a",
																				            name: "aaaa222 - acara",
																				          },
																				          {
																				            id: "057b0a58-5f61-4510-959b-5a54762003d6",
																				            name: "admin portal cambiado a acaraaaaaaaa - acara",
																				          },
																				          {
																				            id: "2eb5502a-a6f3-4c72-9a62-f2e8acf657e7",
																				            name: "administrador",
																				          },
																				          {
																				            id: "2ec336fa-d03d-41c6-b43a-3939e1579cc6",
																				            name: "grupofrontera - acara",
																				          },
																				          {
																				            id: "70e7064e-2ceb-4496-b814-d470cad2b0d2",
																				            name: "grupogreen - acara",
																				          },
																				          {
																				            id: "e1399598-0788-430a-ba05-75e24c2b41c8",
																				            name: "nuevo nombre - acara",
																				          },
																				          {
																				            id: "0a539909-5dc7-4698-8f40-879ffaba0222",
																				            name: "operador",
																				          },
																				          {
																				            id: "cdd1d0bb-af61-4b7d-b405-ea0a642194c2",
																				            name: "rol definido de aseguradora editado - acara",
																				          },
																				          {
																				            id: "55c9c937-9b5d-4268-b3b3-d79c7eb234cc",
																				            name: "users_management",
																				          },
																				        ];*/
					Swal.alertGetInfo("Obteniendo Roles <br><b></b>");

					await this.$axiosApi
						.getById(this.routeRolesByClient, clientId)
						.then((r) => {
							if (r.data.data) {
								this.editedItem.userRoles[index].rolesList = r.data.data;
								console.log("this.editedItem.userRoles[index].rolesList");
								console.log(this.editedItem.userRoles[index].rolesList);
								this.editedItem.userRoles[index].rolesList.forEach((item) => {
									item.roleName = item.name.substr(0, item.name.indexOf(" -"));
								});
								console.log("this.editedItem.userRoles[index].rolesList");
								console.log(this.editedItem.userRoles[index].rolesList);
								Swal.close();
							}
						})
						.catch(function (error) {
							console.log(error);
						});
				}
			},
			getSessions(item) {
				let id = item.id;
				this.$axiosApi
					.getById(this.routeSessions, id)
					.then((r) => {
						this.titleHistorial = "Consulta de Sesiones de " + item.username;
						this.historial = r.data.data;
						this.dialogHistorial = true;
					})
					.catch(function (error) {
						this.snackbar = true;
						this.text = "Error al obtener datos. Error: " + error;
						this.color = "error";
					});
			},
			filterByUsername(item) {
				return this.$filterBy(item, "username");
			},
			filterByFirstName(item) {
				return this.$filterBy(item, "firstName");
			},
			filterByNombre(item) {
				return this.$filterBy(item, "nombre");
			},
			filterByLastName(item) {
				return this.$filterBy(item, "lastName");
			},
			filterByEnabled(item) {
				return this.$filterBy(item, "enabled");
			},
		},
		mounted() {
			this.$hideMenu();
			//this.$getData(["tipo_tramites", "tipo_certificados", "tipo_vehiculos"]);
			console.log("Componente " + this.route + " creado");
		},
	};
</script>
<style scoped>
.text-fields-row {
	display: flex;
}
.x-btn-grid {
	flex: unset;
	background-color: bisque;
	margin: 0 0px;
}
</style>
